import React from "react";

const Callback = () => {

  return (
  <>
    <br/>
    <p style={{margin: "20px"}}>Cargando...</p>
    <br/>
  </>
  );
}

export default Callback;